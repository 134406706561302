<template>
  <div>
    <div class="section-header">
      <div class="row" style="justify-content: right">
        <div class="col-md-2 col-sm-12">
          <v-select
            v-model="channelType"
            :items="items"
            item-value="id"
            item-text="name"
            label="Channel #  "
            class="channel-type"
            style="min-width: 100%;"
            @input="selectChannelType(channelType)"
            outlined
          >
            <template v-slot:prepend>
              <v-icon color="blue darken-2">mdi-television</v-icon>
            </template>
          </v-select>
        </div>
        <v-col cols="2" sm="2" md="2" class="pl-3" style="margin-right: 0">
          <v-select v-if="user &&(user.role_id == '612a40a51a1fcead8871e833' || user.role_id == '611532b19c6e77ad0fd50c1c')"
            v-model="appId"
            :items="items2"
            item-value="id"
            item-text="name"
            label="App Name #  "
            class="app-name"
            style="min-width: 100%;"
            @input="selectAppName(appId)"
            outlined
            >
          </v-select>
        </v-col>
        <div
          class="col-md-2 col-sm-6"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-menu
            v-model="v_startDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pl-5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('header.start')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="v_startDate = false">
            </v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-2 col-sm-6"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-menu
            v-model="v_endDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('header.finish')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="v_endDate = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-2 col-sm-12"
          :class="statusTime ? 'text-right custom-menu' : 'text-right'"
        >
          <v-text-field
            v-model="v_search"
            append-icon="mdi-magnify"
            :label="$t('header.search')"
            single-line
            hide-details
            clearable
            class="pl-5"
          />
        </div>
        <div class="col-md-1 col-sm-12" style="width: 100%; text-align: center">
          <v-btn
            style="display: inline-block; margin-top: 13px"
            class="white--text btn-btn-sucess"
            color="primary"
            depressed
            @click="getData()"
          >
            {{$t("header.search")}}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
    "startTime",
    "endTime",
    "loader",
  ],
  data() {
    return {
      data: {
        descendingFormat: [false],
        sortByFormat: ["amount_win"],
        descending: false,
        sortBy: "amount_win",
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.user_name",
          "_id.balance",
          "amount",
          "amount_win",
          "_id.app_id",
        ],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        statusTime: false,
      },
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_startDate: null,
      v_endDate: null,
      items: [
        { name: "CO1", id: 1 },
        { name: "CO2", id: 2 },
        { name: "CO3", id: 3 },
        { name: "CO4", id: 4 },
        { name: "CO5", id: 15 },
        { name: "CO6", id: 16},
        { name: "KL1", id: 5 },
        { name: "BA1", id: 6 },
        { name: "BA2", id: 7 },
        { name: "BA3", id: 18 },
        { name: "TD1", id: 8 },
        { name: "TD2", id: 9 },
        { name: "TD3", id: 19 },
        { name: "LO1", id: 10 },
        { name: "YU1", id: 11 },
        { name: "AP1", id: 12 },
        { name: "AP2", id: 17 },
        { name: "FT1", id: 13 },
        { name: "TS1", id: 14 },
        { name: "HAN2", id: 20 },
        { name: "HAN3", id: 21 },
        { name: "LOTTO99(SLOT)", id: 90},
        { name: "YUKI(SLOT)", id: 91},
        { name: "PP78(SLOT)", id: 92},
      ],
      channelType: { name: "1", id: 1 },
      appId : 0,
      items2: [
        { name: "All", id: 0 },
        { name: "aa2288", id: 1 },
        { name: "wa855", id: 2 },
        { name: "2bwinner", id: 3 },
        { name: "cf9", id: 4 },
        { name: "cflive", id: 5 },
        { name: "aa18", id: 6 },
        { name: "One2888", id: 7 },
        { name: "a855", id: 8 },
        { name: "wm88", id: 9 },
      ],
      v_startTime: "00:00",
      v_endTime: "23:59",
      loadingScreen: false,
      statusTime: false,
      v_search: "",
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
      this.v_startTime = newVal.startTime;
      this.v_endTime = newVal.endTime;
      this.statusTime = newVal.statusTimeOrigin;
    },
  },
  mounted() {
    if (this.$cookies.get("channel-type")) {
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
    if (this.$cookies.get("app_id")) {
      this.appId = parseInt(this.$cookies.get("app_id"));
    }
  },
  computed: {
    titleInfo: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "List Company Statement";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "List Senior Statement";
        else if (
          this.user.role_name == "SENIOR-VN" ||
          this.user.role_name == "SUB-SENIOR-VN"
        )
          return "List Master Statement";
        else if (
          this.user.role_name == "MASTER-VN" ||
          this.user.role_name == "SUB-MANAGER-VN"
        )
          return "List Agent Statement";
        else if (
          this.user.role_name == "AGENT-VN" ||
          this.user.role_name == "SUB-AGENT-VN"
        )
          return "List Player Statement";
        else return "Loading...";
      } else return "Loading...";
    },
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "company";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "senior";
        else if (
          this.user.role_name == "SENIOR-VN" ||
          this.user.role_name == "SUB-SENIOR-VN"
        )
          return "master";
        else if (
          this.user.role_name == "MASTER-VN" ||
          this.user.role_name == "SUB-MASTER-VN"
        )
          return "agent";
        else if (
          this.user.role_name == "AGENT-VN" ||
          this.user.role_name == "SUB-AGENT-VN"
        )
          return "player";
        else return null;
      } else return null;
    },
  },
  methods: {
    selectAppName(appId) {
      this.$cookies.set("app_id", appId);
      this.appId = parseInt(this.$cookies.get("app_id"));
      if (
        parseInt(this.$cookies.get("app_id")) == 1 ||
        parseInt(this.$cookies.get("app_id")) == 2 ||
        parseInt(this.$cookies.get("app_id")) == 3 ||
        parseInt(this.$cookies.get("app_id")) == 4 ||
        parseInt(this.$cookies.get("app_id")) == 5 ||
        parseInt(this.$cookies.get("app_id")) == 6 ||
        parseInt(this.$cookies.get("app_id")) == 7 ||
        parseInt(this.$cookies.get("app_id")) == 8 
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("app_id")
        );
      //this.$router.go();
    },
    selectChannelType(channelType) {
      this.$cookies.set("channel-type", channelType);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
      if (
        parseInt(this.$cookies.get("channel-type")) == 2 ||
        parseInt(this.$cookies.get("channel-type")) == 3 ||
        parseInt(this.$cookies.get("channel-type")) == 4
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("channel-type")
        );
      this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
      this.loadingScreen = true;
      this.startTime = this.v_startTime;
      this.endTime = this.v_endTime;
      let formatStartDate = "";
      let formatEndDate = "";
      // if(this.statusTime){
      //   formatStartDate = this.startDate + "T" + this.startTime + ":00";
      //   formatEndDate = this.endDate + "T" + this.endTime + ":59";
      // }else{
      //   formatStartDate = this.startDate;
      //   formatEndDate = this.endDate;
      // }
      formatStartDate = this.startDate + "T" + this.startTime + ":00";
      formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.statusTime = this.statusTime ? true : false;
      this.data.search = this.v_search;
      let preData = {
        appId:this.appId,
        data: this.data,
        user_id: this.user._id,
        reportUrl: this.reportUrl,
      };
      this.fetchListStatement(preData).then(() => {
        let date = {
          appId:this.appId,
          startDate: formatStartDate,
          endDate: formatEndDate,
          statusTime: this.statusTime,
        };
        this.$emit("child-checkbox", date);
        this.loadingScreen = false;
      });
    },
    ...mapActions("$_ocStatementVn", ["fetchListStatement"]),
  },
};
</script>
